
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IRichMenu } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_RICH_MENU } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import { constant } from '@/utils/constants'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: { CLoading },
  directives: { maska },
  emits: ['update:changeTab'],
})
export default class RichMenus extends mixins(BaseFormMixin) {
  loading = true
  modalVisible = false
  selectedIRichMenu: IRichMenu = {}

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get richMenus() {
    return cloneDeep(this.$store.getters.richMenus) || []
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    await this.$store.dispatch(ACTION_RICH_MENU.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    this.loading = false
  }

  async updateIsActive(item: IRichMenu) {
    const isSuccess = await this.$store.dispatch(ACTION_RICH_MENU.IS_ACTIVE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async updateTestMode(item: IRichMenu) {
    const isSuccess = await this.$store.dispatch(ACTION_RICH_MENU.TEST_MODE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: IRichMenu) {
    const menu = this.richMenus.find((item) => item._id !== record._id && item.is_active === true)
    if (!menu) {
      if (!record.is_active) {
        this.updateIsActive(record)
      } else {
        this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.updateIsActive(record)
          })
          .onCancel(async () => {
            record.is_active = false
          })
      }
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          menu.is_active = false
          this.updateIsActive(menu)
        })

      record.is_active = false
    }
  }

  async handleToggleTest(record: IRichMenu) {
    const menu = this.richMenus.find((item) => item._id !== record._id && item.is_test_mode === true)
    if (!menu) {
      this.updateTestMode(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          menu.is_test_mode = false
          this.updateTestMode(menu)
        })

      record.is_test_mode = false
    }
  }

  onAdd() {
    this.goto('rich_menu_setting', { app_id: this.selectedAppId, action_type: constant.ACTION_TYPE.ADD })
  }

  onCopy(data: IRichMenu) {
    this.goto('rich_menu_setting', {
      app_id: this.selectedAppId,
      rich_menu_id: data._id,
      action_type: constant.ACTION_TYPE.COPY,
    })
  }

  onEdit(data: IRichMenu) {
    return this.detailPath('rich_menu_setting', {
      app_id: this.selectedAppId,
      rich_menu_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  async onDelete(data: IRichMenu) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_RICH_MENU.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedIRichMenu = {}
    this.appIdChanged()
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  detailPath(name: string, params = {}) {
    return {
      name: name,
      params: params,
    }
  }
}
